/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('.product-box .product-image').matchHeight();
        function setImgHeight() {
          $('.catalog-box .product-image').css('height', 'auto');
          var imageWidth = $('.catalog-box .product-image').width();
          $('.catalog-box .product-image').height(imageWidth);
        }
        setImgHeight();
        $(window).on('resize', function(){
          setImgHeight();
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // flickr gallery
        var photosetId = $('#photoset').data('photoset');
        $('#photoset').nanoGallery({
          kind: 'flickr',
          userID: '109474235@N04',
          photoset: 'none',
          thumbnailWidth: 'auto',
          thumbnailHeight: 200,
          thumbnailLabel: {
            display: false,
          },
          viewerToolbar: {
            display: false,
          },
          theme: 'light',
          thumbnailGutterWidth : 0,
          thumbnailGutterHeight : 0,
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Eventos page.
    'eventos': {
      init: function() {
        $('#events').infinitescroll({
          navSelector: '.navigation',
          nextSelector: '.navigation a:first',
          itemSelector: '#events .event',
          loading: {
            finished: undefined,
            finishedMsg: '<em>Llegaste al evento más antiguo.</em>',
            img: "",
            msg: null,
            msgText: '<em>Cargando más eventos...</em>',
            selector: '#loading',
            speed: 'fast',
            start: undefined
          },
        }).infinitescroll('scroll');
        $('.event.next').not(':last').remove();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
